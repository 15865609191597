@charset "UTF-8";

@font-face {
	font-family: "gilroylight";
	src: url("/assets/fonts/gilroy-light.eot");
	src: url("/assets/fonts/gilroy-light.eot?#iefix") format("embedded-opentype"),
		url("/assets/fonts/gilroy-light.woff2") format("woff2"),
		url("/assets/fonts/gilroy-light.woff") format("woff"),
		url("/assets/fonts/gilroy-light.ttf") format("truetype");
	unicode-range: U+000-5FF;
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "gilroyextrabold";
	src: url("/assets/fonts/gilroy-extrabold.eot");
	src: url("/assets/fonts/gilroy-extrabold.eot?#iefix")
			format("embedded-opentype"),
		url("/assets/fonts/gilroy-extrabold.woff2") format("woff2"),
		url("/assets/fonts/gilroy-extrabold.woff") format("woff"),
		url("/assets/fonts/gilroy-extrabold.ttf") format("truetype");
	unicode-range: U+000-5FF;
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

html {
	font-size: 62.5%;
}

hr {
	margin: 50px 0;
	margin: 5rem 0;
}

body {
	padding: 0;
	margin: 0;
	top: 0;
	left: 0;
	background-color: #000;
	color: white;

	font-size: 16px;
	font-size: 1.6rem;

	line-height: 200%;

	font-family: "gilroylight", Arial, Helvetica, sans-serif;
	font-weight: 600;
}

h1 {
	font-size: 42px;
	font-size: 4.2rem;
	line-height: 120%;
	font-family: "gilroyextrabold", Arial, Helvetica, sans-serif;
}

h2 {
	font-family: "gilroyextrabold", Arial, Helvetica, sans-serif;
}

.logo {
	-ms-interpolation-mode: bicubic;
	image-rendering: optimizeQuality;
	shape-rendering: geometricPrecision;
}

.sr {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

header {
	position: absolute;
	top: 10px;
	left: 10px;
	right: 10px;

	white-space: nowrap;

	border-radius: 8px;

	z-index: 100;
	backdrop-filter: blur(10px);

	height: 90px;
	height: 9rem;
	background-color: black;
	background-color: rgba(20, 53, 45, 0.6);
	font-family: "gilroylight", "Helvetica", "Arial", sans-serif;

	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);

	* {
		float: left;
	}

	nav {
		height: 100%;
	}

	input[type="checkbox"],
	label {
		display: none;
	}

	label {
		float: right;

		color: white;
		text-decoration: none;
		vertical-align: middle;

		line-height: 90px;
		line-height: 9rem;

		font-size: 20px;
		font-size: 2rem;

		position: relative;

		margin-left: 20px;
		margin-left: 2rem;
		margin-right: 20px;
		margin-right: 2rem;

		&::after {
			content: "";
			background-color: white;
			width: 0;
			height: 2px;
			height: 0.2rem;
			display: block;

			top: 60%;
			top: 6rem;

			left: 0;
			right: 0;
			margin: 0 auto;

			position: absolute;

			opacity: 0;

			-webkit-transition: width 0.5s, opacity 0.5s;
			-moz-transition: width 0.5s, opacity 0.5s;
			-o-transition: width 0.5s, opacity 0.5s;
			transition: width 0.5s, opacity 0.5s;
		}

		&:hover::after {
			width: 100%;
			opacity: 1;
		}
	}

	@media (max-width: 45rem), (max-width: 720px) {
		input[type="checkbox"]:not(:checked) + nav {
			display: none;
		}

		label {
			display: block;
		}

		nav {
			position: absolute;
			top: 90px;
			top: 9rem;
			right: 0;

			ul {
				height: auto;
				background: black;
				background: rgba(0, 0, 0, 1);
				display: block;
				position: relative;

				&:last-child {
					width: 100%;
					flex-basis: 100%;
				}

				li {
					margin-bottom: 0;
					width: 100%;
					padding: 5px 20px;
					padding: 0.5rem 2rem;
					margin: 0;

					a {
						width: 100%;
						text-align: right;
						display: block;
					}
				}
			}
		}
	}

	.logo {
		width: 100px;
		width: 10rem;
		height: 72px;
		height: 7.2rem;
		padding: 10px;
		padding: 1rem;
		margin: 9px;
		margin: 0.9rem;

		background: url("/assets/images/icon-small.png") center no-repeat;

		border: none;
		border-radius: 5px;

		-webkit-transition: background-color 0.5s;
		-moz-transition: background-color 0.5s;
		-o-transition: background-color 0.5s;
		transition: background-color 0.5s;

		@media (min-width: 45.063rem), (min-width: 721px) {
			width: 243px;
			width: 24.3rem;
			background: url("/assets/images/logo-small.png") center no-repeat;

			position: relative;

			margin-right: 4rem;

			&::after {
				content: "";
				position: absolute;
				display: block;
				height: 36px;
				height: 3.6rem;
				width: 2px;
				width: 0.2rem;
				background: white;
				right: -24px;
				right: -2.4rem;
				top: 18px;
				top: 1.8rem;
			}
		}

		&:hover {
			background-color: rgb(0, 0, 0, 0.4);
		}
	}

	ul {
		height: 100%;
		display: block;
		margin: 0;
		padding: 0;
		top: 0;
		left: 0;

		li {
			list-style: none;
			display: block;
			height: 100%;

			margin-left: 20px;
			margin-left: 2rem;
			margin-right: 20px;
			margin-right: 2rem;

			a {
				color: white;
				text-decoration: none;
				vertical-align: middle;

				line-height: 90px;
				line-height: 9rem;

				font-size: 20px;
				font-size: 2rem;

				position: relative;

				&::after {
					content: "";
					background-color: white;
					width: 0;
					height: 2px;
					height: 0.2rem;
					display: block;

					top: 60%;
					top: 6rem;

					left: 0;
					right: 0;
					margin: 0 auto;

					position: absolute;

					opacity: 0;

					-webkit-transition: width 0.5s, opacity 0.5s;
					-moz-transition: width 0.5s, opacity 0.5s;
					-o-transition: width 0.5s, opacity 0.5s;
					transition: width 0.5s, opacity 0.5s;
				}

				&:hover::after {
					width: 100%;
					opacity: 1;
				}
			}
		}
	}
}

.full {
	width: 100%;

	box-shadow: inset 0 -60px 60px -20px rgba(0, 0, 0, 0.35);

	.full-header {
		position: relative;
		display: block;
		height: 100%;
		width: 100%;

		padding-top: 90px;
		padding-top: 9rem;

		min-height: 500px;
		min-height: 50rem;

		height: 40vh;
		height: calc(100vh - 50rem);

		overflow: hidden;

		.center {
			max-width: 95%;
			width: 900px;
			width: 90rem;
			top: 30%;
			left: 0;
			right: 0;
			margin: 0 auto;

			@supports (transform: translateY(-50%)) or
				(-webkit-transform: translateY(-50%)) or
				(-ms-transform: translateY(-50%)) or (-o-transform: translateY(-50%)) or
				(-moz-transform: translateY(-50%)) {
				top: calc(50% + 3rem);
				-webkit-transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				-o-transform: translateY(-50%);
				transform: translateY(-50%);
			}

			position: absolute;

			.logo {
				width: 100%;
				padding: 0 7.5%;
				margin-bottom: 10px;
				margin-bottom: 1rem;

				-ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#000')";
				filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#000')";

				-webkit-filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 1));
				filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 1));

				-webkit-filter: drop-shadow(0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 1));
				filter: drop-shadow(0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 1));
			}

			p {
				font-family: "gilroyextrabold", Helvetica, Arial, sans-serif;
				font-weight: 700;
				font-size: 30px;
				font-size: 3rem;
				text-align: center;

				padding: 0;
				margin: 0;
				top: 0;

				text-shadow: 2px 2px 5px #000, 0 0 3px #000, -1px -1px 0 #000,
					1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
				text-shadow: 0.2rem 0.2rem 0.5rem #000 0 0 0.3rem #000,
					-0.1rem -0.1rem 0 #000, 0.1rem -0.1rem 0 #000, -0.1rem 0.1rem 0 #000,
					0.1rem 0.1rem 0 #000;
			}
		}

		.background {
			overflow: hidden;

			width: 100%;
			height: 100%;

			top: 0;
			left: 0;

			position: absolute;

			background-size: cover;
			background-image: url("/assets/images/background.jpg");
			background-repeat: no-repeat;
			background-position: center;

			-ms-behavior: url("/assets/js/backgroundsize.min.htc");

			z-index: -99;
		}

		.background.half {
			top: auto;
			left: auto;
			right: 0;
			bottom: 0;

			width: 120%;
			height: 70%;
			position: absolute;

			-webkit-transform-origin: top right;
			-moz-transform-origin: top right;
			-o-transform-origin: top right;
			-ms-transform-origin: top right;
			transform-origin: top right;

			-webkit-transform: rotate(-10deg);
			-moz-transform: rotate(-10deg);
			-o-transform: rotate(-10deg);
			-ms-transform: rotate(-10deg);
			transform: rotate(-10deg);

			background-size: contain;
			background-image: url("/assets/images/halftone.png");
			background-repeat: repeat-x;

			@media all\0 {
				background-image: url("/assets/images/halftone-baked.png");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: bottom right;

				height: 100%;

				-ms-transform: rotate(0);
				transform: rotate(0);
			}

			@media all and (monochrome: 0) {
				-ms-transform: rotate(-10deg);
				transform: rotate(-10deg);

				height: 70%;

				background-size: contain;
				background-image: url("/assets/images/halftone.png");
				background-repeat: repeat-x;

				-ms-behavior: none;
			}
		}

		& > svg {
			width: 120%;
			height: 750px;

			-webkit-transform-origin: top right;
			-moz-transform-origin: top right;
			-ms-transform-origin: top right;
			-o-transform-origin: top right;
			transform-origin: top right;

			-webkit-transform: rotate(-7deg);
			-moz-transform: rotate(-7deg);
			-ms-transform: rotate(-7deg);
			-o-transform: rotate(-7deg);
			transform: rotate(-7deg);

			position: absolute;
			bottom: 0;
			right: 0;

			rect {
				bottom: 0;
				right: 0;
				position: absolute;
			}
		}
	}

	.option {
		.container {
			.backdrop {
				position: absolute;
				width: 100%;
				height: auto;
				margin: auto 0;
				top: 0;
				bottom: 0;
				left: 0;

				z-index: -5;

				display: inline-block;
				overflow: hidden;

				-webkit-transition: -webkit-transform 0.5s, -webkit-filter 0.5s;
				-moz-transition: -moz-transform 0.5s, -moz-filter 0.5s;
				-o-transition: -o-transform 0.5s, -o-filter 0.5s;
				transition: transform 0.5s, filter 0.5s;

				-webkit-filter: brightness(0.5);
				-moz-filter: brightness(0.5);
				-o-filter: brightness(0.5);
				filter: brightness(0.5);

				user-drag: none;
				user-select: none;
				-moz-user-select: none;
				-webkit-user-drag: none;
				-webkit-user-select: none;
				-ms-user-select: none;
			}

			&:hover .backdrop {
				-webkit-transform: scale(1.1);
				-moz-transform: scale(1.1);
				-ms-transform: scale(1.1);
				-o-transform: scale(1.1);
				transform: scale(1.1);

				-webkit-filter: brightness(0.8);
				-moz-filter: brightness(0.8);
				-o-filter: brightness(0.8);
				filter: brightness(0.8);
			}

			h1 {
				font-family: "gilroyextrabold", Arial, Helvetica, sans-serif;
				font-size: 32px;
				font-size: 3.2rem;
				text-align: center;
				display: table-cell;
				vertical-align: middle;
				height: 100%;

				text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.8);
			}

			.darker {
				@media all\0 {
					background-color: #000;
				}

				@media all and (monochrome: 0) {
					background-color: #000\9;
				}

				@media all and (-ms-high-contrast: none) {
					background-color: #000;
				}

				-ms-filter: "alpha(opacity=40)";
				opacity: 0.4;

				-ms-transition: opacity 0.5s, -ms-filter 0.5s;
				transition: opacity 0.5s, -ms-filter 0.5s;

				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;

				display: block;

				z-index: -4;
			}

			&:hover .darker {
				-ms-filter: "alpha(opacity=20)";
				opacity: 0.2;
			}

			width: 100%;
			height: 100%;

			position: relative;
			overflow: hidden;

			display: table;

			cursor: pointer;

			padding: 20px;
			padding: 2rem;
		}

		color: inherit;
		text-decoration: inherit;

		width: 100%;

		min-height: 20vh;
		height: 240px;
		height: 24rem;
		overflow: hidden;

		display: block;

		box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.9);
		box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.9);
	}
}

.content-container {
	min-height: 200px;
	background-color: white;
	width: 100%;
	display: block;
	color: black;

	position: relative;

	padding: 20px;
	padding: 2rem;
	padding-top: 110px;
	padding-top: 11rem;

	.content {
		max-width: 90%;
		width: 900px;
		width: 90rem;
		left: 0;
		right: 0;
		margin: 0 auto;
		position: relative;

		h1 {
			text-align: center;
		}

		p {
			text-justify: inter-word;
			font-size: 20px;
			font-size: 2rem;
		}

		img {
			width: 100%;
			position: relative;
		}

		.image-link {
			min-width: 40%;
			max-width: 100%;
			width: 500px;
			width: 50rem;

			left: 0;
			right: 0;
			margin: 0 auto;

			margin-bottom: 10px;
			margin-bottom: 1rem;

			display: block;
		}

		.showcase {
			width: 100%;

			position: relative;

			ul {
				display: grid;

				width: 100%;
				list-style-type: none;
				list-style-position: outside;

				padding: 0;
				margin: 0;

				gap: 1.6rem;

				@media (min-width: 45rem) {
					grid-template-columns: 1fr 1fr;
				}

				li {
					max-width: 100%;
					position: relative;
				}

				img {
					object-fit: contain;
					min-height: 100%;
					vertical-align: middle;
				}
			}
		}
	}
}

footer {
	padding: 40px;
	padding: 4rem;

	width: 100%;
	min-height: 100px;
	min-height: 10rem;
	height: auto;

	display: block;

	position: relative;

	.container {
		width: 100%;
		min-height: 100%;
		position: relative;
		display: block;

		&:after {
			clear: both;
			display: table;
			content: "";
		}
	}

	.left,
	.right {
		max-width: 100%;
		min-height: 60px;
		min-height: 6rem;

		position: relative;
		display: block;

		float: left;
	}

	.right {
		float: right;
	}

	.left p,
	.right p {
		height: 100%;
		vertical-align: middle;
	}

	.logo {
		width: 100%;
		height: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
}

form {
	width: 100%;
	font-size: 24px;
	font-size: 2.4rem;

	div {
		width: 100%;
		display: flex;
	}

	label {
		width: 120px;
		width: 12rem;
		margin-right: 10px;
		margin-right: 1rem;

		padding: 5px;
		padding: 0.5rem;

		font-family: "gilroyextrabold", Helvetica, Arial, sans-serif;
	}

	input,
	textarea {
		flex-grow: 1;
		margin: 0;
		margin-bottom: 20px;
		margin-bottom: 2rem;

		padding: 5px;
		padding: 0.5rem;

		font-size: 24px;
		font-size: 2.4rem;

		font-family: "gilroylight", Arial, Helvetica, sans-serif;
		font-weight: 600;
	}
}

b {
	font-family: "gilroyextrabold", Arial, Helvetica, sans-serif;
}

select {
	font-size: 24px;
	font-size: 2.4rem;
	font-family: "gilroylight", Arial, Helvetica, sans-serif;
	font-weight: 600;

	width: 100%;
	max-width: 100%;

	margin-bottom: 20px;
	margin-bottom: 2rem;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
